import Masonry from 'react-masonry-css'
import React from 'react'
import SmileClosedBlue from './img/smileClosedBlue.svg'
import SmileClosedRed from './img/smileClosedRed.svg'
import SmileOpenYellow from './img/smileOpenYellow.svg'
import SmileWinkBlue from './img/smileWinkBlue.svg'
import SmileWinkRed from './img/smileWinkRed.svg'
import Swiper from 'react-id-swiper'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const comments = [
  {
    img: SmileOpenYellow,
    name: 'Полина',
    title:
      'Для первой сессии все было приятно и хорошо в общении со специалистом YouTalk.',
    desc: 'Я была приятно удивлена, насколько легко было себя чувствовать и комфортно общаться, несмотря на то, что это была моя первая онлайн-терапия. Специалист проявил глубокое понимание моих проблем и внимательно выслушал мои мысли и чувства. Я чувствовала, что мне действительно помогают.'
  },
  {
    img: SmileWinkRed,
    name: 'Виктор',
    title: 'Первый сеанс был полезным опытом.',
    desc: 'Общение со специалистом было легким и комфортным, что меня приятно удивило. Психолог внимательно слушал, проявлял глубокое понимание моих проблем и внимательно выслушал мои мысли и эмоции. Также общение с сотрудниками поддержки очень профессионально и по делу, нет лишней назойливости.'
  },
  {
    img: SmileClosedBlue,
    name: 'Ольга',
    title: 'Очень понравилась первая консультация.',
    desc: 'Психолог сразу расположила к себе. Было полное понимание и спокойствие.'
  },
  {
    img: SmileClosedRed,
    name: 'Ямина',
    title:
      'Мы успели познакомиться, я рассказала свою историю и сформировала свой запрос.',
    desc: 'В процессе мне было очень комфортно, мы продолжаем работу.'
  },
  {
    img: SmileClosedBlue,
    name: 'Ольга',
    title:
      'Благодаря профессионализму и теплому отношению специалиста, я почувствовала себя комфортно и понято.',
    desc: 'Каждое слово и вопрос были на месте, психолог смог развернуто выслушать мои проблемы и предложить ценные рекомендации. Я ощущаю, что двигаюсь вперед к решению своих проблем благодаря поддержке и помощи, полученной от сервиса YouTalk. Огромное спасибо за профессионализм и заботу!'
  },
  {
    img: SmileOpenYellow,
    name: 'Тамара',
    title:
      'Работа с сервисом YouTalk максимально понятна и удобна, что для меня имело большое значение.',
    desc: 'После сессии у меня осталось приятное ощущение, ощущение, что кто-то действительно слушает и понимает меня. Я чувствую, что каждая сессия приносит мне больше ясности и понимания. Теперь я точно знаю, что хочу продолжить работу с этим сервисом. Большое спасибо за вашу профессиональную поддержку!'
  },
  {
    img: SmileWinkBlue,
    name: 'Ксения',
    title: 'Спасибо за подбор психолога!',
    desc: 'Очень располагающая к себе специалист, буквально с пары сообщений нащупала болевые моменты и написала верные слова.'
  },
  {
    img: SmileOpenYellow,
    name: 'Зоя',
    title: 'Первая встреча с психологом прошла замечательно',
    desc: 'Она очень расположила меня к себе и я к конце сессии стала более спокойна и расслаблена.'
  },
  {
    img: SmileWinkRed,
    name: 'Карина',
    title:
      'В плане работы с сервисом, все отлично, узнали все до мелочей, нашли специалиста по моим предпочтениям.',
    desc: 'По поводу психолога пока не могу ничего сказать просто поговорили, легко ли пошел разговор, думаю да, в остальном смогу сказать попозже.'
  }
]

const breakpointColumnsObj = {
  default: 3,
  1023: 2,
  767: 1
}

const paramsComments = {
  loop: false,
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 16,
  preventClicks: false,
  preventClicksPropagation: false
}

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 48px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const CommentBox = styled.div`
  @media (max-width: ${size.sm}) {
    width: calc(100% - 64px) !important;
    max-width: calc(100% - 64px) !important;
    margin-bottom: 0;
  }
`

const Comments = styled(({ className }) => {
  const wWidth = useSelector(selectDeviceSize)
  return (
    <>
      {wWidth > 767 ? (
        <StyledMasonry breakpointCols={breakpointColumnsObj}>
          {comments.map((comment, i) => (
            <StyledComment key={`comment${i}`} className={className}>
              <CommentBody>
                <CommentTitle>{comment.title}</CommentTitle>
                <CommentText>{comment.desc}</CommentText>
              </CommentBody>
              <ImageBlock>
                <StyledImage alt="Emoji" src={comment.img} />
                <CommentName>{comment.name}</CommentName>
              </ImageBlock>
            </StyledComment>
          ))}
        </StyledMasonry>
      ) : (
        <StyledSwiper {...paramsComments}>
          {comments.map((comment, i) => (
            <CommentBox key={`comment${i}`}>
              <StyledComment className={className}>
                <CommentBody>
                  <CommentTitle>{comment.title}</CommentTitle>
                  <CommentText>{comment.desc}</CommentText>
                </CommentBody>
                <ImageBlock>
                  <StyledImage alt="Emoji" src={comment.img} />
                  <CommentName>{comment.name}</CommentName>
                </ImageBlock>
              </StyledComment>
            </CommentBox>
          ))}
        </StyledSwiper>
      )}
    </>
  )
})`
  width: 100%;
`

const StyledComment = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
`

const CommentBody = styled.div`
  display: flex;
  flex-direction: column;
`

const CommentTitle = styled(Text.Medium)`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 8px;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const CommentText = styled(Text.Medium)`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const CommentName = styled(Text.Medium)`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-left: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const ImageBlock = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;

  img {
    width: 48px;
    height: 48px;
  }

  @media (max-width: ${size.xs}) {
    width: 112px;
    height: 48px;
  }
`

const StyledMasonry = styled(Masonry)`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

const StyledImage = styled.img`
  width: 32px;
  height: 32px;
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
`

export const CommentsBlock = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <StyledTitle>Отзывы наших клиентов</StyledTitle>
      <Comments />
    </Grid>
  </div>
))`
  width: 100%;
  background-color: #dfebf9;
`
