import PsychologistsImage from './img/aboutPsychologists.svg'
import React from 'react'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { navigate } from 'gatsby'
import { size } from '../../constants'

const advantages = [
  {
    icon: <Icons.IconEducation />,
    text: 'Имеет высшее психологическое образование'
  },
  {
    icon: <Icons.IconCalendarOutline />,
    text: 'Прошел программу повышения квалификации сроком не менее двух лет'
  },
  {
    icon: <Icons.IconBrain />,
    text: 'Использует в своей работе подходы, признанные научным сообществом'
  },
  {
    icon: <Icons.IconUserAdded />,
    text: 'Регулярно проходит супервизию — консультируется со старшим коллегой'
  },
  {
    icon: <Icons.IconBillhead />,
    text: 'Проходит личную терапию, чтобы не смешивать личные сложности и проблемы клиентов'
  },
  {
    icon: <Icons.IconNotebook />,
    text: 'Предоставил рекомендации коллег о своей работе'
  },
  {
    icon: <Icons.IconSmiles />,
    text: 'Прошел тест на толерантность, чтобы разница во взглядах и ценностях с клиентом не мешала работе'
  },
  {
    icon: <Icons.IconWindowCheck />,
    text: 'Имеет успешный опыт опыт работы с запросами, указанными в анкете'
  }
]

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.md}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xxs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-top: 48px;

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    padding-top: 8px;
  }
`

const Advantage = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`

const AdvantageBody = styled.div`
  display: flex;
  padding-top: 16px;

  @media (max-width: ${size.md}) {
    width: 100%;
    align-items: center;
  }
`

const AdvantageTitle = styled(Text.Medium)`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const StyledIcon = styled.div`
  display: flex;
  background-color: #35b3a9;
  border-radius: 100px;
  min-width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 16px;
`

const SeeAllBody = styled.div`
  display: flex;
  margin-top: 24px;

  cursor: pointer;

  align-items: center;
`

const ImageBlock = styled.div`
  display: flex;

  @media (max-width: ${size.xs}) {
    width: 112px;
    height: 48px;
  }
`

const SeeAllText = styled.a`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-left: 16px;
  color: #333333;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const StyledAllImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

const SeeAllTextWrapper = ({ text }) => (
  <SeeAllText dangerouslySetInnerHTML={{ __html: text }} />
)

const SeeAllPsychologistsBlock = styled(({ className }) => (
  <SeeAllBody className={className} onClick={() => navigate('/catalog/')}>
    <ImageBlock>
      <StyledAllImage
        alt="Эмодзи дощечки для записей"
        src="../../../img/seeAll.png"
      />
    </ImageBlock>
    <SeeAllTextWrapper text="Посмотреть<br />все анкеты психологов" />
  </SeeAllBody>
))``

const Advantages = styled(({ className }) => (
  <Advantage className={className}>
    {advantages.map((advantage, i) => (
      <AdvantageBody key={`advantage${i}`}>
        <StyledIcon>{advantage.icon}</StyledIcon>
        <AdvantageTitle>{advantage.text}</AdvantageTitle>
      </AdvantageBody>
    ))}
    <SeeAllPsychologistsBlock />
  </Advantage>
))`
  width: 50%;
  margin-left: 24px;

  @media (max-width: ${size.md}) {
    align-self: start;
    margin-left: 0;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 588px;

  @media (max-width: ${size.lg}) {
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 8px;
    max-width: 100%;
  }

  @media (max-width: ${size.sm}) {
    display: none;
  }
`

const MobileImageContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${size.sm}) {
    display: flex;
    margin-bottom: 24px;
  }
`

const StyledImage = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

export const AboutPsychologists = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <MobileImageContainer>
        <StyledImage src={PsychologistsImage} />
      </MobileImageContainer>
      <StyledTitle>Каждый онлайн психолог Youtalk</StyledTitle>
      <Content>
        <ImageContainer>
          <StyledImage src={PsychologistsImage} />
        </ImageContainer>
        <Advantages />
      </Content>
    </Grid>
  </div>
))`
  width: 100%;
  position: relative;
  background-color: #dfebf9;
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
  }
`
