import React from 'react'
import styled from 'styled-components'
import { Grid } from '../atoms/Grid'
import { QuestionsList } from './QuestionsList'
import { Title } from '../atoms/Title'
import { size } from '../constants'

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  @media (max-width: ${size.sm}) {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
`

const QuestionList = styled(({ className }) => (
  <div className={className}>
    <StyledTitle>Ответы на частые вопросы</StyledTitle>
    <QuestionsList
      onlyOnOpenSendGA
      className={className}
      eventGA="getMainAnswer"
      page="Main"
    />
  </div>
))`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > li > button {
    background-color: transparent;
  }
`

export const QuestionListMain = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <QuestionList />
    </Grid>
  </section>
))`
  background-color: #fff;
  padding-bottom: 48px;
`
