import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Circles } from './img/infoCircles.svg'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'

const StyledText = styled(Text.Medium)`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  padding-top: 24px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 993px;

  @media (max-width: ${size.lg}) {
    max-width: 960px;
  }

  @media (max-width: ${size.md}) {
    max-width: 728px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 374px;
  }

  @media (max-width: ${size.xs}) {
    max-width: 288px;
  }
`

export const InfoBlock = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <Content>
        <Circles />
        <StyledText>
          Сервис по подбору психолога онлайн YouTalk тщательно отбирает
          психологов для сотрудничества, чтобы как можно больше людей получили
          профессиональную психологическую помощь
        </StyledText>
      </Content>
    </Grid>
  </div>
))`
  display: flex;
  padding-top: 120px;
  padding-bottom: 120px;
  justify-content: center;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  @media (max-width: ${size.xxs}) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`
