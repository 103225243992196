import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Grid } from '../../atoms/Grid'
import { Icons } from '../../../youtalk-storybook/src/ui'
import { NewMiniCard } from '../../organisms/PsychologistNewMiniCard'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'
import { useNewOpenForm } from '../../components/GA'

const StyledTitle = styled(Title.H2)`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 48px;

  @media (max-width: ${size.sm}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 24px;
    line-height: 32px;
  }
`

const PsychologistsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  width: 100%;
`

const CenteredButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`

const FullWidthButton = styled(Button.NewOutline)`
  width: 100%;
  max-width: 384px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-left: 16px;
  margin-bottom: 120px;
  background-color: #dfebf9;

  @media (max-width: ${size.lg}) {
    margin-bottom: 72px;
  }

  @media (max-width: ${size.md}) {
    max-width: 100%;
    margin-bottom: 48px;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }

  @media (max-width: ${size.xxs}) {
    margin-bottom: 32px;
  }
`

const Psychologists = ({ psychologists }) => (
  <PsychologistsContainer>
    {psychologists.map((psychologist) => (
      <NewMiniCard key={psychologist.id} psychologist={psychologist} />
    ))}
  </PsychologistsContainer>
)

// eslint-disable-next-line max-lines-per-function
export const PsychologistsBlock = styled(({ className, data }) => {
  const psychologists = useMemo(
    () => data.psychologists.edges.map(({ node }) => node),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.psychologist]
  )

  return (
    <div className={className}>
      <Grid>
        <StyledTitle>Подобрать психолога в сервисе Youtalk</StyledTitle>
        <Psychologists psychologists={psychologists.slice(0, 3)} />
        <CenteredButtonContainer>
          <FullWidthButton
            href="/catalog/"
            onClick={useNewOpenForm('requestsExample')}
            size="large"
            type="link"
            width="fullScreen"
          >
            Посмотреть всех психологов
            <Icons.IconArrowRight />
          </FullWidthButton>
        </CenteredButtonContainer>
      </Grid>
    </div>
  )
})`
  width: 100%;
  background-color: #dfebf9;

  padding-top: 120px;
  background-color: #dfebf9;

  @media (max-width: ${size.lg}) {
    padding-top: 72px;
  }

  @media (max-width: ${size.md}) {
    padding-top: 48px;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
  }
`
